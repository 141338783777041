import React, { useState, useEffect } from "react";
import SettingsWrapper from "common/ScrollableWrapper/SettingsWrapper";
import { useForm } from "react-hook-form";
import toastr from "toastr";
import { addOrUpdateBussinessAddress } from "services/businesses/businesses.service";
import Autocomplete from "react-google-autocomplete";
import { environment } from "../../configs/environment";

const AddressSettings = ({ business, address }) => {
  const [autoCompleteValue, setAutoCompleteValue] = useState("");
  const [form, setForm] = useState({
    address1: business.address?.address1 || "",
    address2: business.address?.address2 || "",
    city: business.address?.city || "",
    state: business.address?.state || "",
    postal: business.address?.postal || "",
    country: business.address?.country || "",
    geo: business.address?.geo || {}
  });

  useEffect(() => {
    if (business) {
      setForm({
        address1: business.address?.address1 || "",
        address2: business.address?.address2 || "",
        city: business.address?.city || "",
        state: business.address?.state || "",
        postal: business.address?.postal || "",
        country: business.address?.country || "",
        geo: business.address?.geo || {}
      });
      setAutoCompleteValue("");
    }
  }, [business, address]);

  const [loading, setLoading] = useState(false);
  const [shouldSubmit, setShouldSubmit] = useState(false);

  const onSubmit = async data => {
    setLoading(true);
    try {
      const res = await addOrUpdateBussinessAddress(business.businessId, data);
      if (res.success) {
        toastr.success("Address updated!");
      } else {
        throw new Error(res.error || "Something went wrong");
      }
    } catch (e) {
      toastr.error(e.message, "Address didn't updated");
    }
    setLoading(false);
  };

  const { handleSubmit } = useForm();

  const onAutoComplete = place => {
    // "street_address" - address1
    // "locality" - city
    // "administrative_area_level_1" - state
    // "postal_code" - postal
    const { address_components, formatted_address, geometry } = place;
    if (!address_components) return;
    if (formatted_address) setAutoCompleteValue(formatted_address);

    const address1 = address_components.find(el => el.types.includes("street_address"));
    const street_number = address_components.find(el => el.types.includes("street_number"));
    const route = address_components.find(el => el.types.includes("route"));
    const city = address_components.find(el => el.types.includes("locality"));
    const state = address_components.find(el => el.types.includes("administrative_area_level_1"));
    const postal = address_components.find(el => el.types.includes("postal_code"));
    const country = address_components.find(el => el.types.includes("country"));

    const geo = {
      lat: geometry.location?.lat?.(),
      lng: geometry.location?.lng?.()
    };

    const data = {
      address1:
        address1?.long_name || ((street_number?.long_name || "") + " " + (route?.short_name || "")).trim() || "",
      address2: form.address2 || "",
      country: country?.short_name || "",
      city: city?.long_name || "",
      state: state?.short_name || state?.long_name || "",
      postal: postal?.long_name || "",
      geo
    };

    setForm(data);
    // for some reasons bussiness id is cached and
    // submit from here always submiting form with primary location bussiness id
    // fixed by "shouldUpdate" var, and setTimemout that garantee that "setForm"
    // complete first, and than form submit
    setTimeout(() => {
      setShouldSubmit(true);
    }, 0);
  };

  useEffect(() => {
    if (shouldSubmit) {
      handleSubmit(onSubmit(form));
      setShouldSubmit(false);
    }
  }, [shouldSubmit, form]);

  // Also prevent form submiting when press "Enter", because pressing also trigger onAutocomplete
  // and form submitting 2 times
  // it doesn't affect on other components
  const checkKeyDown = e => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <SettingsWrapper
      title={`Address ${business ? " - " + business.name : ""}`}
      handleSubmit={handleSubmit(onSubmit)}
      hideSubmit
      buttonStyles={{ maxWidth: 600 }}
      onKeyDown={checkKeyDown}
    >
      <div style={{ maxWidth: 600 }}>
        <div className="settings-label address-warning-message">
          {!loading &&
            (business && business.squareLinked
              ? "Your address can be changed in your Square Dashboard"
              : !form.address1 || !form.city || !form.state || !form.postal || !form.country || !form.geo?.lat
              ? "You currently do not have an address specified. Please add an address."
              : "")}
        </div>
        <div className="flex settings-form-group form-group" style={{ alignItems: "center" }}>
          <div className="settings-label-wrapper">
            <label className="settings-label control-label">Lookup address</label>
          </div>
          <div className="settings-input-wrapper">
            <Autocomplete
              apiKey={environment.googleMapsKey}
              onPlaceSelected={onAutoComplete}
              className="form-control settings-input"
              language="en"
              options={{
                strictBounds: true,
                componentRestrictions: {
                  country: ["us", "ca"]
                },
                types: ["address"],
                fields: ["address_components", "formatted_address", "geometry"]
              }}
              onChange={e => setAutoCompleteValue(e.target.value)}
              value={autoCompleteValue}
              disabled={business && business.squareLinked ? true : loading}
            />
          </div>
        </div>

        <div className="flex settings-form-group form-group" style={{ alignItems: "center" }}>
          <div className="settings-label-wrapper">
            <label className="settings-label control-label">Address</label>
          </div>
          <div className="settings-input-wrapper">
            {form.address1 && <div>{form.address1}</div>}
            {form.address2 && <div>{form.address2}</div>}
            {form.city && form.state && form.postal && (
              <div>
                {form.city}, {form.state} {form.postal}
              </div>
            )}
          </div>
        </div>
      </div>
    </SettingsWrapper>
  );
};

export default AddressSettings;
